import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';

import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import App from './App';
import BiletiniAl from './sections/biletini-al'; // Assuming this component exists
import BiletiniAlAll from './biletinialall'; // Assuming this component exists
export const UserContext = React.createContext(null);

// Set basename for subdirectory deployment, remove `basename` if deployed at the root.
const router = createBrowserRouter(
  [
    {
      path: '/',
      element: <App />, // Main component
    },
    {
      path: '/bilet-al',
      element: <BiletiniAl />, // Assuming BiletiniAl is a valid component
    },
    {
      path: '/biletini-al',
      element: <BiletiniAlAll/>, // Assuming BiletiniAl is a valid component
    },
    // ... other routes
  ],
 
);

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

reportWebVitals();
