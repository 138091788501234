
import React from 'react';
import sosyal_light from './assets/img/sosyal_light.png';
import Footer from './sections/footer/footer';

export default function BiletiniAlAll() {
    return (
        <>
            <div className='innerWidth paddings r-container'>
           <div className='flexColCenter'>
           <span> <img
                            className="nav__logo"
                            src={sosyal_light}
                            alt=""
                            srcSet=""
                            width={150}
                        /></span>
           </div>
           <div className='small-padding'></div>
           <div className='small-padding'></div>
           <span className="hero-des flexColCenter">
               <span className='bold'> Sosyal Sanathane</span> etkinliklerine katılmak için şehirini  seç
                biletini al!
              </span>
              <div className='small-padding'></div>
           <div className='small-padding'></div>
            <div class="
    grid-container
            ">
  <div class="grid-item">
  <a
                  className="citylinkbuttonRouter "
                  onClick={() => {
                    window.location.href =
                      "https://biletinial.com/tr-tr/egitim/workshop-etkinlik-takvimi-sosyal-sanathane-ankara";
                  }}
                >
                  Ankara
                  <span>
                    06
                  </span>
                </a>
  </div>
  <div class="grid-item"><a
                  className="citylinkbuttonRouter"
                  onClick={() => {
                    window.location.href =
                      "https://biletinial.com/tr-tr/egitim/workshop-etkinlik-takvimi-sosyal-sanathane-aydin";
                  }}
                >
                  Aydın
                  <span>
                    09
                  </span>
                </a></div>
  <div class="grid-item"><a
                  className="citylinkbuttonRouter "
                  onClick={() => {
                    window.location.href =
                      "https://biletinial.com/tr-tr/egitim/workshop-etkinlik-takvimi-sosyal-sanathane-mersin";
                  }}
                >
                  Mersin
                  <span>
                    33
                  </span>
                </a></div>  
  <div class="grid-item"> <a
                  className="citylinkbuttonRouter "
                  onClick={() => {
                    window.location.href =
                      "https://biletinial.com/tr-tr/egitim/workshop-etkinlik-takvimi-sosyal-sanathane-manisa";
                  }}
                >
                  Manisa
                  <span>
                    45
                  </span>
                </a></div>
  <div class="grid-item">
  <a
                  className="citylinkbuttonRouter "
                  onClick={() => {
                    window.location.href =
                      "https://biletinial.com/tr-tr/egitim/workshop-etkinlik-takvimi-sosyal-sanathane-balikesir";
                  }}
                >
                  Balıkesir
                  <span>
                    10
                  </span>
                </a>
  </div>
  <div class="grid-item">
  <a
                  className="citylinkbuttonRouter "
                  onClick={() => {
                    window.location.href =
                      "https://biletinial.com/tr-tr/egitim/workshop-etkinlik-takvimi-sosyal-sanathane-fethiye";
                  }}
                >
                  Fethiye
                  <span>
                    48
                  </span>
                </a></div>  

</div>
<br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
                
            </div>
            <Footer></Footer>
        </>
    );
}