import "./App.css";
import About from "./sections/about";
import AboutUs from "./sections/about/about-us";
import SsApp from "./sections/app";
import MobileApp from "./sections/app/mobileapp";
import Branches from "./sections/branches/branches";
import Contact from "./sections/contact/contact";
import Faq from "./sections/faq";
import Value from "./sections/faq/faq";
import Features from "./sections/features/features";
import Footer from "./sections/footer";
import FooterNew from "./sections/footer/footer";
import Hero from "./sections/header/Hero";
import Header from "./sections/header/header";
import Frencaises from "./sections/francaises/frencaises";
import * as React from "react";
import Mekanlar from "./sections/mekanlar/mekanlar";
import BiletiniAl from "./sections/biletini-al";

export default function App() {
  return (
    <div className="main App">
     <BiletiniAl></BiletiniAl>

    </div>
  );
}
