import React from "react";

import Footer from "./footer/footer";
import sosyal_light from '../assets/img/sosyal_light.png';



export default function BiletiniAl() {
  return (
    <div className="">

      <div className="innerWidth paddings r-container">
        <div className="flexColCenter ">
          <div className=" ">
            <div className="r-head flexColCenter">
              <span> <img
                            className="nav__logo"
                            src={sosyal_light}
                            alt=""
                            srcSet=""
                            width={150}
                        /></span>
                             <div className="small-padding "></div>
                             <div className="small-padding "></div>
              <span className="hero-des">
                Sosyal Sanathane etkinliklerine katılmak için <br /> etkinlik yerini seç
                bilet al!
              </span>
              <div className="small-padding "></div>
              <a
              className="citylinkbuttonRouter widthFull"
              onClick={() => {
                window.location.href =
                  "https://biletinial.com/tr-tr/egitim/workshop-etkinlik-takvimi-sosyal-sanathane-izmir";
              }}
            >
              Alsancak Şubesi
              <span>
                <i className="ri-arrow-right-line" />
              </span>

            </a>

            <a
              className="citylinkbuttonRouter widthFull"
              onClick={() => {
                window.location.href =
                  "https://biletinial.com/tr-tr/egitim/workshop-etkinlik-takvimi-sosyal-sanathane-izmir";
              }}
            >
              Bostanlı Şubesi
              <span>
                <i className="ri-arrow-right-line" />
              </span>
            </a>

            <a
              className="citylinkbuttonRouter widthFull"
              onClick={() => {
                window.location.href =
                  "https://biletinial.com/tr-tr/egitim/workshop-etkinlik-takvimi-istinyepark-teras-sosyal-sanathane-izmir";
              }}
            >
              İstinyePark Teras
              <span>
                <i className="ri-arrow-right-line" />
              </span>
            </a>

            <a
              className="citylinkbuttonRouter widthFull"
              onClick={() => {
                window.location.href =
                  "https://biletinial.com/tr-tr/egitim/workshop-etkinlik-takvimi-sosyal-sanathane-izmir";
              }}
            >
              Anlaşmalı Mekanlar
              <span>
                <i className="ri-arrow-right-line" />
              </span>
            </a>
            </div>
          </div>
        </div>
        <div className="small-padding "></div>
        <div className="flexCenter">
          <h4>Diğerler İller</h4>
        </div>
        <div className="small-padding "></div>
            
        <div className="innerWidth paddings r-container flexColCenter">
              <div className="flex cityLinkFlexFloating">
                <a
                  className="citylinkbuttonRouterOther "
                  onClick={() => {
                    window.location.href =
                      "https://biletinial.com/tr-tr/egitim/workshop-etkinlik-takvimi-sosyal-sanathane-ankara";
                  }}
                >
                  Ankara
                  <span>
                    06
                  </span>
                </a>
                <a
                  className="citylinkbuttonRouterOther "
                  onClick={() => {
                    window.location.href =
                      "https://biletinial.com/tr-tr/egitim/workshop-etkinlik-takvimi-sosyal-sanathane-aydin";
                  }}
                >
                  Aydın
                  <span>
                    09
                  </span>
                </a>
                <a
                  className="citylinkbuttonRouterOther "
                  onClick={() => {
                    window.location.href =
                      "https://biletinial.com/tr-tr/egitim/workshop-etkinlik-takvimi-sosyal-sanathane-mersin";
                  }}
                >
                  Mersin
                  <span>
                    33
                  </span>
                </a>
                <a
                  className="citylinkbuttonRouterOther "
                  onClick={() => {
                    window.location.href =
                      "https://biletinial.com/tr-tr/egitim/workshop-etkinlik-takvimi-sosyal-sanathane-manisa";
                  }}
                >
                  Manisa
                  <span>
                    45
                  </span>
                </a>
                <a
                  className="citylinkbuttonRouterOther "
                  onClick={() => {
                    window.location.href =
                      "https://biletinial.com/tr-tr/egitim/workshop-etkinlik-takvimi-sosyal-sanathane-balikesir";
                  }}
                >
                  Balıkesir
                  <span>
                    10
                  </span>
                </a>
                <a
                  className="citylinkbuttonRouterOther "
                  onClick={() => {
                    window.location.href =
                      "https://biletinial.com/tr-tr/egitim/workshop-etkinlik-takvimi-sosyal-sanathane-fethiye";
                  }}
                >
                  Fethiye
                  <span>
                    48
                  </span>
                </a>
              </div>
              
        </div>



      </div>

     
      <br />
      <br />
      <br />
      <Footer></Footer>
    </div>
  );
}
